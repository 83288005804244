import { useState, useEffect } from "react";

const BackgroundSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const bgImages = [
        "https://fixaars.com/storage/11/richard.png",
        "https://fixaars.com/storage/4/felix.png",
        "https://fixaars.com/storage/6/jennifer.png",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % bgImages.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="background-slider">
            {bgImages.map((image, index) => (
                <div
                    key={index}
                    className={`background-slide ${index === currentSlide ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${image})` }}
                ></div>
            ))}
        </div>
    );
};

export default BackgroundSlider;