import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ComingSoonPage.css';
import BackgroundSlider from './BackgroundSlider';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ComingSoonPage = () => {
    const [email, setEmail] = useState('');
    const [sendingEmail, setSendingEmail] = useState(false);
    const [whatsappValid, setWhatsappValid] = useState(true);
    const [emailError, setEmailError] = useState(null);
    const [whatsapp, setWhatsapp] = useState('');
    const [profile, setProfile] = useState('');
    const [fullName, setFullName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [countdownData, setCountdownData] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const launchDate = new Date();
        launchDate.setDate(launchDate.getDate() + 42);

        const interval = setInterval(() => {
            const now = new Date();
            const difference = launchDate - now;

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setCountdownData({ days, hours, minutes, seconds });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false || !profile || !whatsappValid || !whatsapp || !fullName) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        setSendingEmail(true);

        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        const formattedTime = now.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
        });
        const signupTime = `${formattedDate} at ${formattedTime}`;

        const profileDesc = profile === 'service_seeker'
            ? 'Service Seeker - Looking for professional services'
            : 'Service Provider - Offering professional services';

        const emailData = {
            user_name: fullName,
            profile_type: profile === 'service_seeker' ? 'Service Seeker' : 'Service Provider',
            whatsapp_number: whatsapp || 'Not provided',
            user_email: email || 'Not provided',
            profile_description: profileDesc,
            signup_time: signupTime
        };

        fetch('https://fixaars.com/notify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    console.log('SUCCESS!', data);
                    setSubmitted(true);
                } else {
                    console.error('API ERROR:', data.message);
                    setEmailError(data.message || 'Failed to send notification. Please try again later.');
                }
                setSendingEmail(false);
            })
            .catch(error => {
                console.error('NETWORK ERROR:', error);
                setEmailError('Failed to connect to the server. Please try again later.');
                setSendingEmail(false);
            });
    };

    const services = [
        { icon: "hammer", name: "Construction & Repair" },
        { icon: "house-heart", name: "Interior Design" },
        { icon: "bucket", name: "Cleaning Services" },
        { icon: "wrench", name: "Plumbing Services" },
        { icon: "plug", name: "Electrical Work" },
        { icon: "palette", name: "Painting" },
        { icon: "tree", name: "Landscaping" },
        { icon: "droplet", name: "Pest Control" }
    ];

    return (
        <div className="coming-soon-page">
            <BackgroundSlider />

            {/* Animated Background */}
            <div className="animated-bg"></div>


            {/* Hero Section with Header */}
            <header className="hero-section">
                <div className="header-content">
                    <Container>
                        <div className="d-flex justify-content-between align-items-center py-4">
                            <div className="fixaars-logo">
                                <span className="fx-blue">F</span>
                                <span className="fx-blue">X</span>
                                <span className="logo-text">Fixaars</span>
                            </div>
                            <div className="contact-badge">
                                <i className="bi bi-telephone-fill me-2"></i>
                                <span>+15265897485</span>
                            </div>
                        </div>
                    </Container>
                </div>

                <Container className="text-center hero-container">
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="hero-title mb-3">
                                <span className="highlight">Connecting</span> you with trusted professionals
                            </h1>
                            <p className="hero-subtitle mb-5">
                                From home repairs to cleaning services, delivered to your door in minutes
                            </p>

                            {/* Countdown Timer */}
                            <div className="countdown-container d-none">
                                <div className="countdown-title">Launching In</div>
                                <div className="countdown-timer">
                                    <div className="countdown-item">
                                        <div className="countdown-value">{countdownData.days}</div>
                                        <div className="countdown-label">Days</div>
                                    </div>
                                    <div className="countdown-item">
                                        <div className="countdown-value">{countdownData.hours}</div>
                                        <div className="countdown-label">Hours</div>
                                    </div>
                                    <div className="countdown-item">
                                        <div className="countdown-value">{countdownData.minutes}</div>
                                        <div className="countdown-label">Minutes</div>
                                    </div>
                                    <div className="countdown-item">
                                        <div className="countdown-value">{countdownData.seconds}</div>
                                        <div className="countdown-label">Seconds</div>
                                    </div>
                                </div>
                            </div>

                            <Button
                                variant="primary"
                                size="lg"
                                className="hero-cta mt-4"
                                onClick={() => document.getElementById('signup-section').scrollIntoView({ behavior: 'smooth' })}
                            >
                                <i className="bi bi-arrow-down-circle me-2"></i>
                                Join the Waitlist
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </header>

            {/* Main Content */}
            <main>
                {/* Services Section */}
                <section className="services-section">
                    <Container>
                        <div className="section-header text-center mb-5">
                            <h2>Our Services</h2>
                            <p className="section-subheading">Comprehensive professional services for your every need</p>
                            <div className="section-divider"></div>
                        </div>

                        <Row>
                            {services.map((service, index) => (
                                <Col md={3} sm={6} key={index} className="mb-4">
                                    <div className="service-card">
                                        <div className="service-icon">
                                            <i className={`bi bi-${service.icon}`}></i>
                                        </div>
                                        <h3 className="service-title">{service.name}</h3>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </section>

                {/* Locations Section */}
                {/* <section className="locations-section">
                    <Container>
                        <div className="section-header text-center mb-5">
                            <h2>Where We're Launching</h2>
                            <p className="section-subheading">Currently serving these locations with more coming soon</p>
                            <div className="section-divider"></div>
                        </div>

                        <div className="location-cards">
                            <Row>
                                {cities.map((city, index) => (
                                    <Col md={3} sm={6} key={index} className="mb-4">
                                        <div className="location-card">
                                            <div className="location-flag">
                                                <div className={`flag-icon flag-${city.country.toLowerCase()}`}></div>
                                            </div>
                                            <h3 className="location-city">{city.name}</h3>
                                            <p className="location-country">{city.country}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Container>
                </section> */}

                {/* How It Works */}
                <section className="how-it-works-section">
                    <Container>
                        <div className="section-header text-center mb-5">
                            <h2>How Fixaars Works</h2>
                            <p className="section-subheading">Simple, fast, and reliable service at your fingertips</p>
                            <div className="section-divider"></div>
                        </div>

                        <Row className="steps-container">
                            <Col md={4} className="mb-4 mb-md-0">
                                <div className="step-card">
                                    <div className="step-number">1</div>
                                    <div className="step-icon">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <h3 className="step-title">Request a Service</h3>
                                    <p className="step-description">
                                        Tell us what you need, when you need it, and where
                                    </p>
                                </div>
                            </Col>

                            <Col md={4} className="mb-4 mb-md-0">
                                <div className="step-card">
                                    <div className="step-number">2</div>
                                    <div className="step-icon">
                                        <i className="bi bi-person-check"></i>
                                    </div>
                                    <h3 className="step-title">Get Matched</h3>
                                    <p className="step-description">
                                        We connect you with the perfect professional for your task
                                    </p>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div className="step-card">
                                    <div className="step-number">3</div>
                                    <div className="step-icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h3 className="step-title">Job Done</h3>
                                    <p className="step-description">
                                        Your task is completed to your satisfaction, guaranteed
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Testimonials/Benefits Section */}
                <section className="benefits-section">
                    <Container>
                        <Row className="align-items-center">
                            {/* <Col lg={6} className="mb-5 mb-lg-0">
                                <div className="benefits-image">
                                    <img src="/api/placeholder/500/400" alt="Fixaars app preview" className="img-fluid rounded shadow" />
                                    <div className="benefits-badge">
                                        <span>Coming Soon</span>
                                    </div>
                                </div>
                            </Col> */}
                            <Col lg={6} className="mb-5 mb-lg-0">
                                <div className="benefits-image">
                                    <div className="video-container rounded shadow">
                                        <iframe
                                            className="youtube-iframe"
                                            src="https://www.youtube.com/embed/F34QaZYtlK0?si=ojEizPgNruSTQ_ix&controls=0"
                                            title="🚀 Fixaars – The Fastest Way to Fix Anything! 💡🔧🚗"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                    <div className="benefits-badge">
                                        <span>Coming Soon</span>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="benefits-content">
                                    <h2 className="mb-4">Why Choose Fixaars?</h2>

                                    <div className="benefit-item">
                                        <div className="benefit-icon">
                                            <i className="bi bi-shield-check"></i>
                                        </div>
                                        <div className="benefit-text">
                                            <h3>Vetted Professionals</h3>
                                            <p>All service providers undergo thorough background checks and skill verification</p>
                                        </div>
                                    </div>

                                    <div className="benefit-item">
                                        <div className="benefit-icon">
                                            <i className="bi bi-lightning-charge"></i>
                                        </div>
                                        <div className="benefit-text">
                                            <h3>Fast Response</h3>
                                            <p>Get connected to service providers within minutes of your request</p>
                                        </div>
                                    </div>

                                    <div className="benefit-item">
                                        <div className="benefit-icon">
                                            <i className="bi bi-star"></i>
                                        </div>
                                        <div className="benefit-text">
                                            <h3>Quality Guarantee</h3>
                                            <p>We ensure every job is completed to your satisfaction</p>
                                        </div>
                                    </div>

                                    <div className="benefit-item">
                                        <div className="benefit-icon">
                                            <i className="bi bi-wallet2"></i>
                                        </div>
                                        <div className="benefit-text">
                                            <h3>Transparent Pricing</h3>
                                            <p>No hidden fees or surprises - know the price before booking</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Sign Up Section */}
                <section id="signup-section" className="signup-section">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8} md={10}>
                                <Card className="signup-card">
                                    <Card.Body className="p-4 p-md-5">
                                        {!submitted ? (
                                            <>
                                                <div className="text-center mb-4">
                                                    <div className="signup-icon">
                                                        <i className="bi bi-envelope-paper"></i>
                                                    </div>
                                                    <h2>Be First To Know When We Launch</h2>
                                                    <p className="text-muted">Join our waitlist to get early access and exclusive offers</p>
                                                </div>

                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Group className="mb-3" controlId="formFullName">
                                                                <Form.Label>Full Name <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter your full name"
                                                                    value={fullName}
                                                                    onChange={(e) => setFullName(e.target.value)}
                                                                    required
                                                                    className={validated && !fullName ? 'is-invalid' : ''}
                                                                />
                                                                {validated && !fullName && (
                                                                    <div className="invalid-feedback d-block">
                                                                        Please enter your full name.
                                                                    </div>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formWhatsapp">
                                                                <Form.Label>WhatsApp Number <span className="text-danger">*</span></Form.Label>
                                                                <div className="position-relative">
                                                                    <div className="whatsapp-icon-overlay">
                                                                        <i className="bi bi-whatsapp"></i>
                                                                    </div>
                                                                    <PhoneInput
                                                                        country={'us'}
                                                                        value={whatsapp}
                                                                        onChange={(phone) => {
                                                                            setWhatsapp(phone);
                                                                            setWhatsappValid(phone.length >= 8);
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'whatsapp',
                                                                            required: true,
                                                                            className: validated && (!whatsapp || !whatsappValid) ? 'is-invalid form-control' : 'form-control',
                                                                            style: { width: '100%' } // Add this line to ensure full width
                                                                        }}
                                                                        containerClass="input-with-icon-phone"
                                                                        inputClass="form-control"
                                                                        buttonClass="dropdown-toggle"
                                                                        dropdownClass="country-dropdown"
                                                                        containerStyle={{ width: '100%' }} // Add this line
                                                                    />
                                                                    {validated && (!whatsapp || !whatsappValid) && (
                                                                        <div className="invalid-feedback d-block">
                                                                            Please provide a valid WhatsApp number.
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3" controlId="formEmail">
                                                                <Form.Label>Email Address (Optional)</Form.Label>
                                                                <div className="input-with-icon">
                                                                    <i className="bi bi-envelope"></i>
                                                                    <Form.Control
                                                                        type="email"
                                                                        placeholder="your@email.com"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        className="ps-5"
                                                                    />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Form.Group className="mb-4" controlId="formProfile">
                                                        <Form.Label>Select Your Profile <span className="text-danger">*</span></Form.Label>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <div
                                                                    className={`profile-card ${profile === 'service_seeker' ? 'selected' : ''}`}
                                                                    onClick={() => setProfile('service_seeker')}
                                                                >
                                                                    <div className="profile-icon">
                                                                        <i className="bi bi-person"></i>
                                                                    </div>
                                                                    <h3>Service Seeker</h3>
                                                                    <p>Looking for professional services</p>
                                                                </div>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <div
                                                                    className={`profile-card ${profile === 'service_provider' ? 'selected' : ''}`}
                                                                    onClick={() => setProfile('service_provider')}
                                                                >
                                                                    <div className="profile-icon">
                                                                        <i className="bi bi-briefcase"></i>
                                                                    </div>
                                                                    <h3>Service Provider</h3>
                                                                    <p>Offering professional services</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {validated && !profile && (
                                                            <div className="text-danger small mt-2">
                                                                Please select your profile.
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                    {/* Dans votre bouton de soumission */}
                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        className="w-100 py-3 btn-fixaars"
                                                        disabled={sendingEmail}
                                                    >
                                                        {sendingEmail ? (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                Sending...
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="bi bi-bell me-2"></i>
                                                                Keep Me Updated
                                                            </>
                                                        )}
                                                    </Button>

                                                    {emailError && (
                                                        <Alert variant="danger" className="mt-3">
                                                            {emailError}
                                                        </Alert>
                                                    )}

                                                    <div className="text-center mt-3 text-muted small">
                                                        <i className="bi bi-shield-check me-1"></i>
                                                        Your information is secure and will never be shared
                                                    </div>
                                                </Form>
                                            </>
                                        ) : (
                                            <div className="text-center py-5 thank-you-container">
                                                <div className="checkmark-animation">
                                                    <i className="bi bi-check-circle-fill"></i>
                                                </div>
                                                <h2>Thank You!</h2>
                                                <p className="mb-4">
                                                    You're now on our exclusive waitlist! We'll notify you as soon as Fixaars launches in your area.
                                                </p>
                                                <div className="share-section">
                                                    <p className="mb-3 text-muted">Share Fixaars with others:</p>
                                                    <div className="social-icons">
                                                        <a href="#" className="social-icon">
                                                            <i className="bi bi-facebook"></i>
                                                        </a>
                                                        <a href="#" className="social-icon">
                                                            <i className="bi bi-twitter"></i>
                                                        </a>
                                                        <a href="#" className="social-icon">
                                                            <i className="bi bi-whatsapp"></i>
                                                        </a>
                                                        <a href="#" className="social-icon">
                                                            <i className="bi bi-linkedin"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <Button
                                                    variant="outline-primary"
                                                    className="mt-4"
                                                    onClick={() => setSubmitted(false)}
                                                >
                                                    Back to form
                                                </Button>
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>

            {/* Footer */}
            <footer className="footer">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={4} className="mb-4 mb-lg-0">
                            <div className="fixaars-logo">
                                <span className="fx-blue">F</span>
                                <span className="fx-blue">X</span>
                                <span className="logo-text">Fixaars</span>
                            </div>
                            <p className="mt-3 footer-tagline">
                                Connecting professionals with customers
                            </p>
                        </Col>

                        <Col lg={4} className="text-center mb-4 mb-lg-0">
                            <div className="footer-links">
                                <a href="#" className="footer-link">About Us</a>
                                <a href="#" className="footer-link">Privacy Policy</a>
                                <a href="#" className="footer-link">Terms of Service</a>
                                <a href="#" className="footer-link">Contact Us</a>
                            </div>
                        </Col>

                        <Col lg={4} className="text-lg-end">
                            <div className="footer-contact">
                                <p className="mb-1">
                                    <i className="bi bi-envelope me-2"></i>
                                    contact@fixaars.com
                                </p>
                                <p className="mb-1">
                                    <i className="bi bi-telephone me-2"></i>
                                    +15265897485
                                </p>
                            </div>
                            <div className="social-icons mt-3">
                                <a href="#" className="social-icon">
                                    <i className="bi bi-facebook"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="bi bi-instagram"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="bi bi-twitter"></i>
                                </a>
                                <a href="#" className="social-icon">
                                    <i className="bi bi-linkedin"></i>
                                </a>
                            </div>
                        </Col>
                    </Row>

                    <hr className="footer-divider" />

                    <div className="text-center copyright">
                        &copy; 2024 All Rights Reserved by JS Morlu LLC
                    </div>
                </Container>
            </footer>
        </div>
    );
};

export default ComingSoonPage;